import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { produce } from 'immer';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IPartner } from '../model/partner.model';
import { PartnerService } from '../service/partner.service';
import { GetPartner, GetTermsOfService } from './partner.actions';

export class PartnerStateModel {
  public termsOfService: any;
  public partner: IPartner;
  public partnerLogo: string | null;
  public partnerTOS: string;
  public isPartnerLoaded: boolean;
}


@State<PartnerStateModel>({
  name: 'partner',
  defaults: {
    partner: null,
    termsOfService: null,
    partnerLogo: null,
    partnerTOS: null,
    isPartnerLoaded: false,
  },
})
@Injectable()
export class PartnerState {
  @Selector()
  static getTermsOfService(state: PartnerStateModel) {
    return state.termsOfService;
  }

  @Selector()
  static isPartnerLoaded(state: PartnerStateModel) {
    return state.isPartnerLoaded;
  }

  @Selector()
  static getPartnerLogo(state: PartnerStateModel) {
    return state.partnerLogo;
  }

  @Selector()
  static getPartnerTOS(state: PartnerStateModel) {
    return state.partnerTOS;
  }


  constructor(private partnerService: PartnerService) {
  }


  @Action(GetPartner, {cancelUncompleted: true})
  public GetPartner(ctx: StateContext<PartnerStateModel>) {
    return this.partnerService.getPartnerSelf().pipe(tap(partner => {
      const state = produce(ctx.getState(), draft => {
        draft.partner = partner;
        draft.partnerLogo = environment.logoUrl.replace('{id}', partner.id);
        draft.partnerTOS = partner.branding.termsOfService;
      });

      ctx.setState(state);

    }), catchError((error: HttpErrorResponse): Observable<any> => {
      return throwError(() => new HttpErrorResponse(error));
    }));
  }


  @Action(GetTermsOfService)
  GetTermsOfService(ctx: StateContext<PartnerStateModel>) {
    const id = ctx.getState().partner.debitorId;

    return this.partnerService.getTermsOfService(id).pipe(tap(result => {
      const state = produce(ctx.getState(), draft => {
        draft.termsOfService = result;
      });

      ctx.setState(state);
    }), catchError((error: HttpErrorResponse): Observable<any> => {
      return throwError(() => new HttpErrorResponse(error));
    }));
  }
}
